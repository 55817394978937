import { getCookie } from '~/services/helpers'
import { getAds } from '~/services/ads'

const PAID_TRX = 'PAID:TRX'
const PAID_VARUN = 'PAID:VARUN'
const PAID = 'PAID'
const NOT_CAMPAIGN = 'ORGANIC'
const REMOVE_PART = 'GA1.1.'

export const ADS_RAW_KEY = 'raw-ads'
export const ADS_KEY = 'ads'
export const ADS_FIRST_KEY = 'first-ads'

export const state = () => ({
  source: null,
  gclid: null,
  campaign: null,
  ads: null
})

export const getters = {
  index (state) {
    return state.source
  },
  gclid (state) {
    return state.gclid
  },
  campaign (state) {
    return state.campaign
  },
  isTRX (state) {
    return state.campaign === PAID_TRX
  },
  ads (state) {
    return state.ads
  }
}

export const actions = {
  sourceDetect ({ commit, getters }, req) {
    if (process.client) {
      if (!getters.index) commit('setSource', window.document.referrer)
    } else {
      commit('setSource', req.headers.referer)
    }
  },
  gclidDetect ({ commit }, query) {
    if (query.gclid !== undefined) {
      commit('setGclid', query.gclid)
    } else {
      commit('setGclid', localStorage.getItem('gclid'))
    }
  },
  campaignDetect ({ commit, getters }, query) {
    if (query.source === 'varun') {
      commit('setCampaign', PAID_VARUN)
    } else if (getters.ads?.type === 'utm' && getters.ads?.source === 'bing' && (
      getters.ads?.medium === 'cpc'
      || getters.ads?.medium === 'ppc'
      || getters.ads?.medium === 'paid')
    ) {
      commit('setCampaign', PAID)
    } else if (query.isTRX !== undefined) {
      commit('setCampaign', PAID_TRX)
    } else if (query.gclid !== undefined) {
      commit('setCampaign', PAID)
    } else {
      const campaign = localStorage.getItem('campaign')
      commit('setCampaign', campaign ?? NOT_CAMPAIGN)
    }
  },
  adsDetect ({ commit }, query) {
    const ads = getAds(query)

    let ga = getCookie('_ga')
    if (ga && ga.includes(REMOVE_PART)) {
      ga = ga.slice(REMOVE_PART.length)
    }

    ads.ads_raw.ga_client_id = ga

    commit('setAds', ads)
  }
}

export const mutations = {
  setSource (state, source) {
    state.source = source
  },
  setGclid (state, gclid) {
    if (gclid) localStorage.setItem('gclid', gclid)
    else localStorage.removeItem('gclid')
    state.gclid = gclid
  },
  setCampaign (state, campaign) {
    localStorage.setItem('campaign', campaign)
    state.campaign = campaign
  },
  setAds (state, ads) {
    localStorage.setItem(ADS_RAW_KEY, JSON.stringify(ads.ads_raw))
    localStorage.setItem(ADS_KEY, JSON.stringify(ads.ads))
    state.ads = ads.ads_raw
  }
}
